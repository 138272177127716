<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.push('/')"
                            />
                            <DxButton
                                type="default"
                                icon="mdi mdi-help-circle-outline"
                                class="ml-1"
                                style="opacity: 0"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div
                            class="grow text-xs-center"
                            :style="[eventSearch ? 'opacity: 0' : 'opacity: 1']"
                        >
                            LỆNH VẬN CHUYỂN
                        </div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 1">
                            <DxButton
                                v-if="!eventSearch"
                                type="default"
                                icon="mdi mdi-magnify "
                                class="ml-1"
                                @click="showTextBoxSearch"
                            />

                            <DxButton
                                v-else
                                type="default"
                                icon="mdi mdi-close "
                                class="ml-1"
                                @click="clearTextBoxSearch"
                            />

                            <DxButton
                                type="default"
                                icon="mdi mdi-filter-variant"
                                class="ml-1"
                                @click="
                                    () => {
                                        forceRerender();
                                        dialogLoc = true;
                                    }
                                "
                            />
                            <DxButton
                                v-if="
                                    $Core.AuthApi.TokenParsed.preferred_username !=
                                    '0888888888'
                                "
                                type="default"
                                icon="mdi mdi-help-circle-outline"
                                class="ml-1"
                                @click="MoTrangHuongDan"
                            />
                        </div>
                    </slot>
                    <Transition name="fade">
                        <div class="style-search" v-show="eventSearch">
                            <DxTextBox
                                ref="txtTimKiem"
                                v-model="txtTimKiem"
                                mode="text"
                                styling-mode="underlined"
                                placeholder="Nhập từ khóa tìm kiếm..."
                                :elementAttr="{ class: 'text-box-noline' }"
                                valueChangeEvent="keyup"
                            />
                        </div>
                    </Transition>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false" class="content-box">
            <div>
                <div class="toolbar-view-list">
                    <div
                        class="row"
                        style="height: 48px; border-bottom: 1px solid #dadce0"
                    >
                        <SapXepVue @sapXepTuyen="sapXepTuyen" />
                    </div>
                    <div class="row px-2">
                        <div class="xs12 control-view-list">
                            <DxButton
                                type="normal"
                                icon="mdi mdi-arrow-left-drop-circle-outline"
                                class="ml-1"
                                @click="changeViewByStep(-1)"
                            />
                            <span
                                @click="showMenuDropDown"
                                :style="{
                                    color: viewList.find((e) => e.id == Get_viewPage)
                                        .color,
                                }"
                                >{{
                                    viewList.find((e) => e.id == Get_viewPage).name
                                }}</span
                            >
                            <DxButton
                                type="normal"
                                icon="mdi mdi-arrow-right-drop-circle-outline "
                                class="mr-1"
                                @click="changeViewByStep(1)"
                            />
                            <div v-if="dropMenu" class="dropDown-item">
                                <div
                                    class="control-view-item"
                                    v-for="(item, index) in viewListCustom"
                                    :key="index"
                                    @click="changeView(item.id)"
                                    :data-delay="index"
                                >
                                    <span
                                        :style="{
                                            color: item.color,
                                        }"
                                        >{{ item.name }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DanhSachChuyenDiDuKien
                    :dataLenh="DanhSachLenh"
                    v-if="Get_viewPage == this.$t('TrangThaiLenh_LocTuyen.DuKien')"
                />
                <DanhSachLenhDaCap
                    :dataLenh="DanhSachLenhDaCap"
                    v-if="Get_viewPage == this.$t('TrangThaiLenh_LocTuyen.DaCap')"
                    @click="huyLenh"
                />
                <DanhSachLenhDangThucHien
                    :dataLenh="DanhSachLenhDangThucHien"
                    v-if="Get_viewPage == this.$t('TrangThaiLenh_LocTuyen.DangThucHien')"
                />
                <DanhSachLenhDaHoanThanh
                    :dataLenh="DanhSachLenhDaHoanThanh"
                    v-if="Get_viewPage == this.$t('TrangThaiLenh_LocTuyen.HoanThanh')"
                />
                <DanhSachLenhKhongHoanThanh
                    :dataLenh="DanhSachLenhKhongHoanThanh"
                    v-if="
                        Get_viewPage == this.$t('TrangThaiLenh_LocTuyen.KhongHoanThanh')
                    "
                />
                <PopupVue
                    height="auto"
                    v-model:dialog="dialogThongBao"
                    title=""
                    :useButtonLeft="false"
                    @close="
                        () => {
                            dialogThongBao = false;
                        }
                    "
                    heightScrollView="auto"
                >
                    <template #content>
                        <ThongBaoVue
                            :message="ParamThongBao.message"
                            :state="ParamThongBao.state"
                            :title="ParamThongBao.title"
                        />
                    </template>
                </PopupVue>
                <PopupVue
                    height="auto"
                    v-model:dialog="dialogLoc"
                    title="Lọc dữ liệu"
                    buttonTextRight="Xác nhận"
                    buttonTextLeft="Hủy"
                    @close="closeLoc"
                    classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
                    :key="`${componentKey}-3`"
                >
                    <template #content>
                        <PopupLocDuLieuVue ref="LocDuLieu" :filter="filter" />
                    </template>
                </PopupVue>
                <PopupVue
                    height="auto"
                    v-model:dialog="Get_dialogHuyLenh"
                    title="Bạn có chắc muốn hủy lệnh điện tử này?"
                    buttonTextRight="Xác nhận"
                    buttonTextLeft="Hủy"
                    @close="closeLocLenhDienTu"
                    classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
                    :key="`${componentKey}-3`"
                >
                </PopupVue>
                <PopupBanTheHienLenhVue
                    :dialog="Get_DialogTheHien"
                    :guidLenh="Get_GUID"
                    @closes="
                        () => {
                            $store.commit('DVVTLenh/SET_DIALOG_THEHIEN', false);
                        }
                    "
                />
                <PopupVue
                    height="auto"
                    v-model:dialog="Get_DialogMatMaBaoMat"
                    title="Nhập mã bảo mật"
                    buttonTextRight="Xác nhận"
                    buttonTextLeft="Hủy"
                    @close="closeLocMatMaBaoMat"
                    classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
                    :key="`${componentKey}-3`"
                >
                    <template #content>
                        <PopupMatMaKyLenhVue ref="popupMatMaLenh" />
                    </template>
                </PopupVue>
                <PopupVue
                    height="auto"
                    v-model:dialog="Get_DialogKyLaiLenh"
                    title="Bạn có chắc muốn ký lại lệnh điện tử này?"
                    buttonTextRight="Xác nhận"
                    buttonTextLeft="Hủy"
                    @close="KyLaiLenh"
                    classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
                    :key="`${componentKey}-3`"
                >
                </PopupVue>
                <PopupVue
                    height="auto"
                    v-if="ifDialogDungHanhTrinh"
                    v-model:dialog="Get_DialogDungHanhTrinh"
                    title="Xác nhận xe gặp sự cố"
                    buttonTextRight="Xác nhận"
                    buttonTextLeft="Hủy"
                    @close="closeLocDungHanhTrinh"
                    classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
                    :key="`${componentKey}-3`"
                >
                    <template #content>
                        <PopupDungHanhTrinhVue ref="popupDungHanhTrinh" />
                    </template>
                </PopupVue>

                <PopupVue
                    height="auto"
                    v-if="ifPopupQuanLyXacNhanLenh"
                    v-model:dialog="PopupQuanLyXacNhanLenh"
                    title="Bạn có chắc chắn xác nhận lệnh điện tử này?"
                    buttonTextRight="Xác nhận"
                    buttonTextLeft="Hủy"
                    @close="QuanLyXacNhanLenh"
                    classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
                    :key="`${componentKey}-3`"
                ></PopupVue>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    onIonViewWillEnter,
    onIonViewDidLeave,
    onIonViewWillLeave,
} from "@ionic/vue";
import { DxButton, DxTextBox } from "devextreme-vue";
import ThanhTieuDeVue from "../../../src/components/_Common/ThanhTieuDe";
import DanhSachChuyenDiDuKien from "./components/DanhSachChuyenDiDuKien/DataList.vue";
import DanhSachLenhDaCap from "./components/DanhSachLenhDaCap/DataList.vue";
import DanhSachLenhDangThucHien from "./components/DanhSachLenhDangThucHien/DataList.vue";
import DanhSachLenhDaHoanThanh from "./components/DanhSachLenhDaHoanThanh/DataList.vue";
import DanhSachLenhKhongHoanThanh from "./components/DanhSachLenhKhongHoanThanh/DataList.vue";
import PopupLocDuLieuVue from "./components/PopupLocDuLieu.vue";
import PopupVue from "../../components/_Common/Popup.vue";
import SapXepVue from "./components/SapXep.vue";
import PopupBanTheHienLenhVue from "./components/PopupBanTheHienLenh.vue";
import PopupMatMaKyLenhVue from "./components/PopupMatMaKyLenh.vue";
import PopupDungHanhTrinhVue from "./components/PopupDungHanhTrinh.vue";
export default {
    components: {
        DxButton,
        ThanhTieuDeVue,
        DanhSachChuyenDiDuKien,
        IonPage,
        IonHeader,
        IonContent,
        IonToolbar,
        DxTextBox,
        PopupLocDuLieuVue,
        PopupVue,
        DanhSachLenhDaCap,
        DanhSachLenhDangThucHien,
        DanhSachLenhDaHoanThanh,
        DanhSachLenhKhongHoanThanh,
        SapXepVue,
        PopupBanTheHienLenhVue,
        PopupMatMaKyLenhVue,
        PopupDungHanhTrinhVue,
    },
    data() {
        return {
            filter: {
                TrangThai: "1",
                TuNgay: new Date(),
                DenNgay: new Date(),
                TuyenVanChuyen: "00000000-0000-0000-0000-000000000000",
            },
            eventSearch: false,
            dialogKetThucChuyenDi: false,
            dialogTiepNhanLenh: false,
            dialogDungHanhTrinh: false,
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            dialogLoc: false,
            componentKey: 0,
            viewList: [
                {
                    id: this.$t("TrangThaiLenh_LocTuyen.DuKien"),
                    name: "Danh sách chuyến đi kế hoạch",
                    color: "rgba(0,0,0,.54)",
                },
                {
                    id: this.$t("TrangThaiLenh_LocTuyen.DaCap"),
                    name: " Danh sách lệnh Đã cấp cho lái xe ",
                    color: "#fb8c00",
                },
                {
                    id: this.$t("TrangThaiLenh_LocTuyen.DangThucHien"),
                    name: "Danh sách lệnh Đang thực hiện",
                    color: "#03a9f4",
                },
                {
                    id: this.$t("TrangThaiLenh_LocTuyen.HoanThanh"),
                    name: "Danh sách lệnh Đã hoàn thành",
                    color: "#13b000",
                },
                {
                    id: this.$t("TrangThaiLenh_LocTuyen.KhongHoanThanh"),
                    name: "Danh sách lệnh Không hoàn thành",
                    color: "#d10909",
                },
            ],
            viewPage: 1,
            NgayLoc: new Date(),
            TuNgay: this.$Helper.getStartDay(new Date()),

            DenNgay: this.$Helper.getEndDay(new Date()),
            DanhSachLenh: [],
            DanhSachLenhDaCap: [],
            DanhSachLenhDangThucHien: [],
            DanhSachLenhDaHoanThanh: [],
            DanhSachLenhKhongHoanThanh: [],
            dataLuong: [],
            txtTimKiem: "",
            dropMenu: false,
            dialogBanTheHienLenh: false,
            dialogLocMatMaLenh: false,
            paramsXacNhanKyLaiLenh: {
                WidthTitle: "90%",
                Title: "Bạn có chắc muốn ký lại lệnh điện tử này?",
                btnLeft: "HỦY",
                btnRight: "XÁC NHẬN",
            },
            ifPopupQuanLyXacNhanLenh: false,
            PopupQuanLyXacNhanLenh: false,
            ifDialogDungHanhTrinh: false,
        };
    },
    computed: {
        Get_viewPage() {
            return this.$store.state.DVVTLenh.viewPage;
        },
        viewListCustom() {
            return this.viewList.filter((item) => item.id != this.Get_viewPage);
        },
        Get_dialogHuyLenh() {
            return this.$store.state.DVVTLenh.dialogHuyLenh;
        },
        Get_idLenh() {
            return this.$store.state.DVVTLenh.idLenhDienTu;
        },
        Get_GUID() {
            return this.$store.state.DVVTLenh.GuidLenh;
        },
        Get_DialogTheHien() {
            return this.$store.state.DVVTLenh.dialogTheHien;
        },
        Get_IdChuyenDiBanVe() {
            return this.$store.state.DVVTLenh.IdChuyenDiBanVeDangChon;
        },
        Get_DialogMatMaBaoMat() {
            return this.$store.state.DVVTLenh.dialogMatMaBaoMat;
        },
        Get_DialogKyLaiLenh() {
            return this.$store.state.DVVTLenh.dialogKyLaiLenh;
        },
        Get_DialogDungHanhTrinh() {
            return this.$store.state.DVVTLenh.dialogDungHanhTrinh;
        },
    },
    watch: {
        txtTimKiem(value) {
            setTimeout(() => {
                if (value == this.txtTimKiem) {
                    this.LocLenh();
                    this.LayDanhSachLenhDaCap();
                    this.LayDanhSachLenhDangThucHien();
                    this.LayDanhSachLenhDaHoanThanh();
                    this.LayDanhSachLenhKhongHoanThanh();
                }
            }, 1100);
        },
        PopupQuanLyXacNhanLenh() {
            if (!this.PopupQuanLyXacNhanLenh) {
                this.NgatDOMPopup("ifPopupQuanLyXacNhanLenh");
            }
        },
        Get_DialogDungHanhTrinh() {
            if (!this.Get_DialogDungHanhTrinh) {
                this.NgatDOMPopup("ifDialogDungHanhTrinh");
            } else {
                this.ifDialogDungHanhTrinh = true;
            }
        },
    },
    methods: {
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        sapXepTuyen(item) {
            switch (this.Get_viewPage) {
                case parseInt(this.$t("TrangThaiLenh_LocTuyen.DuKien")):
                    this.LocLenh();
                    break;
                case parseInt(this.$t("TrangThaiLenh_LocTuyen.DaCap")):
                    this.LayDanhSachLenhDaCap();
                    break;
                case parseInt(this.$t("TrangThaiLenh_LocTuyen.DangThucHien")):
                    this.LayDanhSachLenhDangThucHien();
                    break;
                case parseInt(this.$t("TrangThaiLenh_LocTuyen.HoanThanh")):
                    this.LayDanhSachLenhDaHoanThanh();
                    break;
                case parseInt(this.$t("TrangThaiLenh_LocTuyen.KhongHoanThanh")):
                    this.LayDanhSachLenhKhongHoanThanh();
                    break;
                default:
                    break;
            }
        },
        forceRerender() {
            this.componentKey += 1;
        },
        toastThongBao(type, message) {
            let toast = {
                message: message,
                type: type,
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.dialogThongBao = true;
        },
        thongBaoLoi(message, log) {
            this.dialogKetThucChuyenDi = false;
            this.thongBao("Error", message || "Đã xảy ra lỗi. Vui lòng thử lại");
        },
        showTextBoxSearch() {
            this.eventSearch = true;
            setTimeout(() => {
                this.$refs.txtTimKiem.instance.focus();
            }, 100);
        },
        clearTextBoxSearch() {
            this.txtTimKiem = "";
            this.eventSearch = false;
        },
        changeView(viewPage) {
            this.$store.commit("DVVTLenh/Set", {
                key: "viewPage",
                data: parseInt(viewPage),
            });
            this.dropMenu = false;
        },
        changeViewByStep(num) {
            if (num == -1) {
                if (this.Get_viewPage <= 1) {
                    this.$store.commit("DVVTLenh/Set", {
                        key: "viewPage",
                        data: 5,
                    });
                } else {
                    this.$store.commit("DVVTLenh/Set", {
                        key: "viewPage",
                        data: this.Get_viewPage - 1,
                    });
                }
            } else {
                if (this.Get_viewPage >= 5) {
                    this.$store.commit("DVVTLenh/Set", {
                        key: "viewPage",
                        data: 1,
                    });
                } else {
                    this.$store.commit("DVVTLenh/Set", {
                        key: "viewPage",
                        data: this.Get_viewPage + 1,
                    });
                }
            }
        },
        beforeEnter(el) {
            el.style.transform = "translateY(-100px)";
            el.style.opacity = 0;
        },

        enter(el) {
            gsap.to(el, {
                duration: 0.2,
                y: 0,
                opacity: 1,
                delay: el.dataset.delay * 0.2,
            });
        },
        async closeLocLenhDienTu(param) {
            if (!param) {
                this.$store.commit("DVVTLenh/SET_DIALOG_LENH", false);
                return;
            }
            try {
                //Lấy thông tin lọc dữ liệu
                let result = await this.$store.dispatch("DVVTLenh/HuyLenh", {
                    IdLenhDienTu: this.Get_idLenh,
                });
                if (result.Data.errorCode == 0 || result.Data.status == true) {
                    this.LayDanhSachLenhDaCap();
                    this.LayDanhSachLenhDangThucHien();

                    this.toastThongBao("success", "Hủy lệnh thành công!");
                } else {
                    this.toastThongBao("error", result.Data.message);
                }
                this.$store.commit("DVVTLenh/SET_DIALOG_LENH", false);
            } catch (err) {
                console.log(err);
                this.toastThongBao("error", err);
            }
        },
        async closeLocMatMaBaoMat(param) {
            if (!param) {
                this.$store.commit("DVVTLenh/SET_DIALOG_BAO_MAT", false);
                this.$refs.popupMatMaLenh.$refs.validateMaBaoMat.instance.reset();
                return;
            }
            try {
                let validMK = this.$refs.popupMatMaLenh.$refs.validateMaBaoMat.instance.validate();
                if (!validMK.isValid) {
                    return this.$Helper.Common.autoFocusError(validMK);
                } else {
                    let DuLieuGuiDi = {
                        DanhSachLenh: [this.Get_IdChuyenDiBanVe],
                        MaBaoMat: this.$refs.popupMatMaLenh.MaBaoMat,
                    };
                    // Lấy thông tin lọc dữ liệu
                    let result = await this.$store.dispatch(
                        "DVVTLenh/TaoChuyenDiBanVeTheoLenh",
                        DuLieuGuiDi,
                    );
                    console.log(result);
                    if (result.errorCode == 0 || result.status == true) {
                        this.LayDanhSachLenhDaCap();
                        this.toastThongBao("success", "Tạo chuyến đi bán vé thành công!");
                    } else {
                        this.toastThongBao("error", result.message);
                    }
                }

                this.$store.commit("DVVTLenh/SET_DIALOG_BAO_MAT", false);
            } catch (err) {
                console.log(err);
                this.toastThongBao("error", err);
            }
        },
        closeLoc(param) {
            if (!param) {
                this.dialogLoc = false;
                return;
            }
            try {
                //Lấy thông tin lọc dữ liệu
                let valid = this.$refs.LocDuLieu.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    return this.$Helper.Common.autoFocusError(valid);
                }
                let model = this.$refs.LocDuLieu.model;
                let trangThai = model.TrangThai;
                switch (parseInt(trangThai)) {
                    case 1:
                        this.$store.commit("DVVTLenh/Set", {
                            key: "viewPage",
                            data: parseInt(trangThai),
                        });

                        break;
                    case 2:
                        this.$store.commit("DVVTLenh/Set", {
                            key: "viewPage",
                            data: parseInt(trangThai),
                        });

                        break;
                    case 3:
                        this.$store.commit("DVVTLenh/Set", {
                            key: "viewPage",
                            data: parseInt(trangThai),
                        });
                        break;
                    case 4:
                        this.$store.commit("DVVTLenh/Set", {
                            key: "viewPage",
                            data: parseInt(trangThai),
                        });
                        break;
                    case 5:
                        this.$store.commit("DVVTLenh/Set", {
                            key: "viewPage",
                            data: parseInt(trangThai),
                        });
                        break;

                    default:
                        break;
                }
                this.filter.TuNgay = model.TuNgay;
                this.filter.DenNgay = model.DenNgay;
                this.filter.TuyenVanChuyen = model.TuyenVanChuyen;

                this.LocLenh();
                this.LayDanhSachLenhDaCap();
                this.LayDanhSachLenhDangThucHien();
                this.LayDanhSachLenhDaHoanThanh();
                this.LayDanhSachLenhKhongHoanThanh();

                this.dialogLoc = false;
                //Không lọc theo đang thực hiện
            } catch (error) {
                this.toastThongBao("error", "Lỗi hệ thống!");
            }
        },

        onItemClickTab(e) {
            switch (parseInt(e)) {
                case 1:
                    this.viewPage = parseInt(trangThai);
                    this.LocLenh();
                    break;
                case 2:
                    this.viewPage = parseInt(trangThai);
                    this.LayDanhSachLenhDaCap();
                    break;
                case 3:
                    this.viewPage = parseInt(trangThai);
                    this.LayDanhSachLenhDangThucHien();
                    break;
                case 4:
                    this.viewPage = parseInt(trangThai);
                    this.LayDanhSachLenhDaHoanThanh();
                    break;
                case 5:
                    this.viewPage = parseInt(trangThai);
                    this.LayDanhSachLenhKhongHoanThanh();
                    break;

                default:
                    break;
            }
        },
        showMenuDropDown() {
            this.dropMenu = !this.dropMenu;
        },
        async LocLenh() {
            try {
                let key = "ID_KeHoach";
                let query = {
                    TuNgay: this.$Helper.getStartDay(this.filter.TuNgay),
                    DenNgay: this.$Helper.getStartDay(this.filter.DenNgay),
                    IdDnvtTuyen: this.filter.TuyenVanChuyen,
                };
                let textTimKiem = this.txtTimKiem;
                // let listFieldSearch = ["BienSoXe", "TenTuyen"];
                let listFieldSearch = [
                    "TenBenXeDi",
                    "TenBenXeDen",
                    "TenDoiXe",
                    "MaTuyen",
                ];
                this.DanhSachLenh = (
                    await this.$store.dispatch(
                        "DVVTLenh/Reload_DanhSachChuyenDiDuKienV1",
                        {
                            textTimKiem: textTimKiem,
                            listFieldSearch: listFieldSearch || [],
                            query: query,
                            key: key,
                        },
                    )
                ).Store;
            } catch (ex) {
                console.log("🚀 ex", ex);
            }
        },
        async LayDanhSachLenhDaCap() {
            try {
                let key = "ID_KeHoach";
                let query = {
                    TuNgay: this.$Helper.getStartDay(this.filter.TuNgay),
                    DenNgay: this.$Helper.getStartDay(this.filter.DenNgay),
                    IdDnvtTuyen: this.filter.TuyenVanChuyen,
                };
                let textTimKiem = this.txtTimKiem;
                let listFieldSearch = ["BienSoXe", "TenTuyen"];
                this.DanhSachLenhDaCap = (
                    await this.$store.dispatch("DVVTLenh/Reload_DanhSachLenhDaCap", {
                        textTimKiem: textTimKiem,
                        listFieldSearch: listFieldSearch || [],
                        query: query,
                        key: key,
                    })
                ).Store;
            } catch (ex) {
                console.log("🚀 ex", ex);
            }
        },
        async LayDanhSachLenhDangThucHien() {
            try {
                let key = "ID_KeHoach";
                let query = {
                    TuNgay: this.$Helper.getStartDay(this.filter.TuNgay),
                    DenNgay: this.$Helper.getStartDay(this.filter.DenNgay),
                    IdDnvtTuyen: this.filter.TuyenVanChuyen,
                };
                let textTimKiem = this.txtTimKiem;
                let listFieldSearch = ["BienSoXe", "TenTuyen"];
                this.DanhSachLenhDangThucHien = (
                    await this.$store.dispatch(
                        "DVVTLenh/Reload_DanhSachLenhDangThucHien",
                        {
                            textTimKiem: textTimKiem,
                            listFieldSearch: listFieldSearch || [],
                            query: query,
                            key: key,
                        },
                    )
                ).Store;
            } catch (ex) {
                console.log("🚀 ex", ex);
            }
        },
        async LayDanhSachLenhDaHoanThanh() {
            try {
                let key = "ID_KeHoach";
                let query = {
                    TuNgay: this.$Helper.getStartDay(this.filter.TuNgay),
                    DenNgay: this.$Helper.getStartDay(this.filter.DenNgay),
                    IdDnvtTuyen: this.filter.TuyenVanChuyen,
                };
                let textTimKiem = this.txtTimKiem;
                let listFieldSearch = ["BienSoXe", "TenTuyen"];
                this.DanhSachLenhDaHoanThanh = (
                    await this.$store.dispatch(
                        "DVVTLenh/Reload_DanhSachLenhDaHoanThanh",
                        {
                            textTimKiem: textTimKiem,
                            listFieldSearch: listFieldSearch || [],
                            query: query,
                            key: key,
                        },
                    )
                ).Store;
            } catch (ex) {
                console.log("🚀 ex", ex);
            }
        },
        async LayDanhSachLenhKhongHoanThanh() {
            try {
                let key = "ID_KeHoach";
                let query = {
                    TuNgay: this.$Helper.getStartDay(this.filter.TuNgay),
                    DenNgay: this.$Helper.getStartDay(this.filter.DenNgay),
                    IdDnvtTuyen: this.filter.TuyenVanChuyen,
                };
                let textTimKiem = this.txtTimKiem;
                let listFieldSearch = ["BienSoXe", "TenTuyen"];
                this.DanhSachLenhKhongHoanThanh = (
                    await this.$store.dispatch(
                        "DVVTLenh/Reload_DanhSachLenhKhongHoanThanh",
                        {
                            textTimKiem: textTimKiem,
                            listFieldSearch: listFieldSearch || [],
                            query: query,
                            key: key,
                        },
                    )
                ).Store;
            } catch (ex) {
                console.log("🚀 ex", ex);
            }
        },
        async KyLaiLenh(param) {
            if (!param) {
                this.$store.commit("DVVTLenh/SET_DIALOG_KY_LAI_LENH", false);
                return;
            }
            try {
                //Lấy thông tin lọc dữ liệu
                this.$startLoading;
                let rs = await this.$store.dispatch("DVVTLenh/KyLaiLenh", {
                    IdLenhDienTu: [this.Get_idLenh],
                });
                if (rs.Data.status == true) {
                    this.toastThongBao("success", "Ký lại lệnh thành công!");
                } else {
                    this.toastThongBao(
                        "error",
                        "Ký lại lệnh thất bại! " + rs.Data.message,
                    );
                }

                this.LocLenh();
                this.LayDanhSachLenhDaCap();
                this.LayDanhSachLenhDangThucHien();
                this.LayDanhSachLenhDaHoanThanh();
                this.LayDanhSachLenhKhongHoanThanh();
                this.$store.commit("DVVTLenh/SET_DIALOG_KY_LAI_LENH", false);
            } catch (err) {
                console.log(err);
                this.toastThongBao("error", err);
            } finally {
                this.$stopLoading;
            }
        },
        async closeLocDungHanhTrinh(param) {
            if (!param) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "dialogDungHanhTrinh",
                    data: false,
                });
                return;
            }
            try {
                this.$startLoading;
                //Lấy thông tin lọc dữ liệu
                let valid = this.$refs.popupDungHanhTrinh.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    return this.$Helper.Common.autoFocusError(valid);
                }
                let dataPost = {
                    IdLenh: this.Get_idLenh,
                    LyDo: this.$refs.popupDungHanhTrinh.lyDoDungHanhTrinh,
                };
                let rs = await this.$store.dispatch("DVVTLenh/DungHanhTrinh", {
                    DataPost: dataPost,
                });
                if (rs.Data.status == true) {
                    this.toastThongBao("success", "Xác nhận xe gặp sự cố thành công!");
                    this.LayDanhSachLenhDangThucHien();
                } else {
                    this.toastThongBao(
                        "error",
                        "Xác nhận xe gặp sự cố thất bại! " + rs.Data.message,
                    );
                }
                this.$store.commit("DVVTLenh/Set", {
                    key: "dialogDungHanhTrinh",
                    data: false,
                });
            } catch (err) {
                console.log(err);
                this.toastThongBao("error", err);
            } finally {
                this.$stopLoading;
            }
        },
        MoTrangHuongDan() {
            this.$router.push({
                path: "/huong-dan-su-dung/Xem-Chi-Tiet-Huong-Dan",
                query: {
                    name: this.$i18n.t("UrlHuongDan.QuanLyLenhChoDoanhNghiep"),
                },
            });
        },
        LayChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            try {
                this.$store.dispatch(
                    "DVVTLenh/LayChiTietCauHinhDoanhNghiepTheoMaCauHinh",
                    {
                        DanhSachMaCauHinhCanLay: [
                            this.$t("MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe"),
                        ],
                    },
                );
            } catch (error) {
                console.log("🚀 ~ error:", error);
            }
        },
        async QuanLyXacNhanLenh(bool) {
            if (!bool) {
                this.PopupQuanLyXacNhanLenh = false;
                return;
            }
            try {
                let rs = await this.$Events.RequestPost({
                    keyRequest: "CanBoXacNhanKiemTraXe",
                    ServicesBE: "QuanLyLenh",
                    url: this.$t("urlDNQuanLyLenh.CanBoXacNhanKiemTraXe"),
                    dataPost: {
                        IdLenhs: [this.Get_idLenh],
                    },
                    msgSuccess: "Quản lý xác nhận lệnh cho lái xe thành công!",
                    msgError: "Quản lý xác nhận lệnh cho lái xe thất bại!",
                    msgCatch: "Quản lý xác nhận lệnh cho lái xe thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Toast",
                });
                this.LayDanhSachLenhDaCap();
                this.LayDanhSachLenhDangThucHien();
            } catch (error) {
                console.log("🚀 ~ error:", error);
            } finally {
                this.PopupQuanLyXacNhanLenh = false;
            }
        },
    },

    async created() {
        onIonViewWillEnter(() => {
            this.LayChiTietCauHinhDoanhNghiepTheoMaCauHinh();
            this.LocLenh();
            this.LayDanhSachLenhDaCap();
            this.LayDanhSachLenhDangThucHien();
            this.LayDanhSachLenhDaHoanThanh();
            this.LayDanhSachLenhKhongHoanThanh();
            this.$store.dispatch("DVVTLenh/LayCauHinhSuDung");
            let self = this;
            this.emitter.on("DNVTLenh_ShowPopup", ({ name, ParamThongBao }) => {
                self.showPopup(name);
                if (ParamThongBao) {
                    self.ParamThongBao.title = ParamThongBao.title;
                    self.ParamThongBao.message = ParamThongBao.message;
                    self.ParamThongBao.state = ParamThongBao.state;
                }
            });
        });

        onIonViewWillLeave(() => {
            this.emitter.off("DNVTLenh_ShowPopup");
        });
    },
};
</script>
<style lang="css">
.style-search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    left: 38px;
    width: calc(100% - 150px);
    background: #03a9f4;
}
.text-box-noline.dx-texteditor.dx-editor-underlined::after {
    border-bottom: unset;
}
.text-box-noline .dx-texteditor-input,
.text-box-noline .dx-placeholder {
    color: #fff;
    font-size: 12px;
}
.toolbar-view-list {
    position: fixed;
    top: 36px;
    left: 0;
    right: 0;
    z-index: 99;
    background: #fff;
}
.control-view-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    box-shadow: 0px 2px 9px #ccc;
}
.control-view-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.control-view-list,
.control-view-item > span {
    font-weight: 600;
}
.dropDown-item {
    position: absolute;
    width: 95%;
    top: 115%;
    background: #fff;
    z-index: 999;
    left: 0;
    box-shadow: 0px 2px 9px #ccc;
    left: 50%;
    padding-top: 20px;
    transform: translateX(-50%);
}
.fade-enter-active,
.fade-leave-active {
    transform: translate(0%, -50%);
    transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: translate(-100%, -50%);
    transition: all 0.2s ease;
}

.content-box {
    width: 100%;
    height: calc(100% - 36px);
}
</style>
